@import "styles/mixins.scss";

.welcome-layout {
  @include welcome-layout("../images/shake-hand-bg.png");

  &.--shake-hands {
    .header {
      @include flex-box(row, flex-start, center);

      padding-left: 34px;
      height: 47px;
      background-color: $primary_color;
    }

    .shake-hand-title,
    .enquiry-title {
      color: $primary_color;
      font-size: 26px;
      margin-bottom: 8px;
    }

    .enquiry-description {
      font-size: $text_size_3;
      color: $text_color_secondary;
    }

    .main-enquiry-container {
      @include grid(459px, auto, center, center, unset);

      height: calc(70vh - 47px);

      .enquirey-wrapper {
        @include grid(auto, auto, center, center, 25px);

        height: auto;

        .back-link {
          width: 102px;

          .link {
            @include grid(repeat(2, auto), auto, center, center, 7px);

            color: $text_color_secondary;
          }
        }
      }
    }

    .main-container {
      @include grid(37px auto, auto, center, start, 25px);

      margin-top: 38px;

      .shake-hand-form {
        .lease-label {
          color: $text_color_secondary;
          font-size: $text_size_3;
          font-weight: 600;
          margin: 20px auto 7px;
        }

        .shake-hand-switch {
          .ant-radio-group {
            display: flex;

            @include gap(12px);

            .ant-radio-button-wrapper {
              height: 43px;
              width: 84px;
              border-radius: 4px;
              border: none;
              background-color: $bg_gray_color;
              color: $text_color_secondary;
              display: grid;
              place-items: center;
              padding: 7px 12px;

              span {
                &:last-child {
                  display: grid;
                  place-items: center;
                  width: 60px;
                  height: 100%;
                  font-size: $text_size_4;;
                  margin: auto;
                }
              }

              &.ant-radio-button-wrapper-checked {
                background-color: $secondary_color;

                &:focus {
                  box-shadow: 0 0 0 3px $secondary_color;
                }
              }

              &::before {
                display: none;
              }
            }
          }
        }

        .shake-hand-bikes {
          .ant-select {
            width: 373px;

            .ant-select-selector {
              height: 46px;

              .ant-select-selection-search {
                display: flex;
                align-items: center;
              }

              .ant-select-selection-item {
                display: flex;
                align-items: center;
              }
              .ant-select-selection-placeholder {
                margin-top: 7px;
              }
            }
          }
        }

        .shake-hand-upload {
          .upload-document {
            width: 373px;
            height: 72px;

            .ant-upload-btn {
              padding: 8px;
            }

            .ant-upload-drag-container {
              @include grid(24px 304px, auto, center, center, 11px);

              padding: 0;

              .upload-icon {
                margin-top: 6px;
                color: $light_gray_color;
              }
              .text {
                width: 304px;
                font-size: $text_size_4;;
              }
            }
          }
        }

        .shake-hand-phone {
          .react-tel-input {
            .login-phone-input {
              width: 373px;
              height: 46px;
            }

            .flag-dropdown {
              .selected-flag {
                width: 70px;

                .flag {
                  transform: scale(1.5);
                  left: 25%;
                }
              }
            }
          }
        }

        .shake-hand-email {
          .email-input {
            width: 373px;
            height: 46px;
          }
        }

        .action-btn-container {
          display: flex;
          justify-content: flex-end;
          margin-top: 17px;

          @include gap(8px);

          .ant-btn {
            height: 34px;
            width: 74px;
          }
        }
      }
    }
  }
}
