@import "styles/theme.scss";
@import "styles/mixins.scss";

@include filters;

.common-filter {
  .__filter-header {
    display: flex;
    flex-direction: column;

    .all-options {
      display: flex;
      .filter-text {
        color: $text_color_light;
        font-size: $text_size_6;
      }
    }
  }

  .__common-collapse {
    .shift-time-container {
      .ant-collapse-content {
        .ant-collapse-content-box {
          .start {
            margin-bottom: 12px;
          }

          .label {
            font-size: $text_size_6;
            line-height: 25px;
          }
        }
        .time-container {
          display: grid;
          grid-template-columns: 90px 0px 68px;
          grid-template-rows: 32px;
          gap: 5px;
          margin: 2px 0 6px 0;

          .anticon-clock-circle,
          .anticon-close-circle {
            color: $primary_color;
          }
          .ant-radio-group {
            .ant-radio-button-wrapper {
              padding: 1px 7px;
              width: 33px;
              height: 36px;
              text-transform: lowercase;
            }
          }
        }
      }
    }

    .safety-rating-container,
    .assigned-vehicle-container {
      .slider-container {
        display: flex;
        position: relative;
        align-items: center;

        .custom-slider {
          flex: 1;
          .ant-slider-rail {
            background-color: $medium_gray_color;
            height: 2px;
          }
          .ant-slider-track {
            background-color: $primary_color;
            height: 2px;
          }

          .ant-slider-handle {
            border-color: $primary_color;
            background-color: $primary_color;
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }
}
