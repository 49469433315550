@import "styles/theme.scss";

.inner-layout-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-left: 18px;

  .tab-header-container {
    height: 56px;
    padding: 10px 0;
    border-bottom: 1px solid $separator_color;
  }

  .tab-nav-container {
    height: 32px;
    background: rgba($gray_color, 0.07);
    border-bottom: 1px solid $separator_color;
  }

  .content-body {
    flex: 1;
    display: flex;
    width: 100%;
    min-height: 0;
    overflow-y: auto;

    &.--dashboard-content {
      background-color: rgba($gray_color, 0.18);
    }

    .content-container {
      flex: 1;
      margin-right: 13px;
      min-width: 0;

      &.--dashboard-content,
      &.--financial-content {
        margin-right: 0;
        min-height: 100%;
      }
    }

    .filter-container {
      width: 220px;
      min-height: 80vh;
      overflow-y: auto;
      border-left: 1px solid $separator_color;

      * {
        font-size: $text_size_5;
      }
    }

    .enquiry-container {
      width: 317px;
      min-height: 80vh;
      overflow-y: auto;
      background-color: #f9f9f9;

      * {
        font-size: $text_size_5;
      }
    }
  }
}
