.action-buttons-container {
  @include flex-box(row, flex-end, center);
  @include gap(10px);

  gap: 10px; //TODO: remove thgis if the above mixin works
  padding-bottom: 15px;

  .action-button {
    margin: 0;
  }
}
