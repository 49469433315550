@import "styles/theme.scss";

.common-table {
  .ant-table-cell {
    .functional-status-cell {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 1px;

      &::before {
        margin-right: 11px;
      }

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }

      &.--green {
        &::before {
          background: $success_color;
        }
      }

      &.--orange {
        &::before {
          background: $secondary_color;
        }
      }

      &.--red {
        &::before {
          background: $error_color;
        }
      }

      &.--yellow {
        &::before {
          background: $yellow_color;
        }
      }

      &.--purple {
        &::before {
          background: $primary_color;
        }
      }

      &.--gray {
        &::before {
          background: $light_gray_color;
        }
      }
    }

    .service-status-cell {
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 1px;

          &::before {
            margin-right: 11px;
          }

          &::before {
            content: "";
            width: 7px;
            height: 7px;
            border-radius: 50%;
          }

          &.--green {
            &::before {
              background: $success_color;
            }
          }

          &.--orange {
            &::before {
              background: $secondary_color;
            }
          }

          &.--red {
            &::before {
              background: $error_color;
            }
          }

          &.--yellow {
            &::before {
              background: $yellow_color;
            }
          }

          &.--purple {
            &::before {
              background: $primary_color;
            }
          }

          &.--gray {
            &::before {
              background: $light_gray_color;
            }
          }
        }

    .update-vehicle {
      @include flex-box(row, center, center);

      @include gap(6px);
    }
  }
}

.vehicles-content {
  tr {
    .ant-table-cell:nth-child(2) {
      padding-left: 4px;
    }
  }
}
