@import "styles/theme.scss";
@import "styles/pages/welcome.scss";
@import "styles/pages/shake-hands.scss";
@import "styles/pages/login-form.scss";
@import "styles/pages/login-verify.scss";
@import "styles/components/layout.scss";
@import "styles/components/next-step.scss";
@import "styles/components/inner-layout.scss";
@import "styles/components/app-header.scss";
@import "styles/components/app-nav.scss";
@import "styles/components/tab-headers/vehicles-header.scss";
@import "styles/components/tab-headers/dashboard-header.scss";
@import "styles/components/tab-filters/vehicles-filter.scss";
@import "styles/components/tab-content/vehicles-content/cell-renders.scss";
@import "styles/components/tab-content/vehicles-content/vehicle-detail.scss";
@import "styles/components/tab-content/vehicles-content/vehicles-content.scss";
@import "styles/components/tab-content/issues-content/cell-renders.scss";
@import "styles/components/tab-content/users-content/cell-renders.scss";
@import "styles/components/tab-content/dashboard-content/dashboard-content.scss";
@import "styles/components/tab-headers/issues-header.scss";
@import "styles/components/tab-filters/issues-filter.scss";
@import "styles/components/tab-filters/users-filter.scss";
@import "styles/components/tab-filters/zones-filter.scss";
@import "styles/components/tab-content/zones-content/zones-content.scss";
@import "styles/components/tab-content/zones-content/cell-renders.scss";
@import "styles/components/tab-content/users-content/cell-renders.scss";
@import "styles/components/tab-content/users-content/user-detail.scss";
@import "styles/components/tab-content/client-content/cell-renders.scss";
@import "styles/components/tab-content/financials-content/cell-renders.scss";
@import "styles/components/settings-content.scss";
@import "styles/components/charts/tile-column-chart.scss";
@import "styles/components/charts/column-chart.scss";
@import "styles/components/charts/bar-chart.scss";
@import "styles/components/charts/pie-chart.scss";
@import "styles/components/charts/financial-widget.scss";
@import "styles/components/charts/fixed-placement-bar.scss";
@import "styles/components/checkbox-group.scss";
@import "styles/components/radio-group.scss";
@import "styles/components/base/radio.scss";
@import "styles/components/tab-navs.scss";
@import "styles/components/map.scss";
@import "styles/components/tile.scss";
@import "styles/components/loading-screen.scss";
@import "styles/components/user-tile.scss";
@import "styles/components/alert.scss";
@import "styles/components/enquiries.scss";

@import "styles/components/base/search.scss";
@import "styles/components/base/dropdown-with-search.scss";
@import "styles/components/base/switch-button.scss";
@import "styles/components/base/checkbox.scss";
@import "styles/components/base/table.scss";
@import "styles/components/base/tag.scss";
@import "styles/components/base/date-picker.scss";

@import "styles/forms/add-vehicles.scss";
@import "styles/forms/issues-form.scss";
@import "styles/forms/add-users.scss";
@import "styles/forms/add-task.scss";
@import "styles/forms/edit-shift.scss";
@import "styles/forms/add-payment.scss";
@import "styles/forms/edit-user.scss";
@import "styles/forms/edit-role.scss";
@import "styles/forms/upload-pic.scss";
@import "styles/forms/import-csv.scss";
@import "styles/forms/delete.scss";
@import "styles/components/form-item.scss";

@font-face {
  font-family: "HelveticaNeue" !important;
  src: local("HelveticaNeue"), url("../fonts/HelveticaNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeue-Light" !important;
  src: local("HelveticaNeue-Light"),
    url("../fonts/HelveticaNeue-Light.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeue-Medium" !important;
  src: local("HelveticaNeue-Medium"),
    url("../fonts/HelveticaNeue-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeue-Bold" !important;
  src: local("HelveticaNeue-Bold"), url("../fonts/HelveticaNeue-Bold.ttf") format("truetype");
}

:root {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background-color: $white_color;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: $white_color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar_gray_color;
  border-radius: 3px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif !important;

  .vertical-separator {
    margin: 0 12px;
    border-right: 1px solid $separator_color;
  }
}

.ant-select-selection-item {
  color: $text_color_secondary;
  align-items: center;
}

input {
  &.ant-input {
    @extend .ant-select-selection-item;

    width: 225px;
  }
}

.ant-picker {
  max-width: 225px;

  .ant-picker-suffix {
    color: $primary_color;
  }
}

.actions-container {
  .ant-btn {
    height: 34px;
  }
}

.chart-card {
  .rv-xy-plot__series .rv-xy-plot__series--mark {
    > circle {
      stroke: $white_color !important;
    }
  }

  .line-chart-tick-styles {
    .rv-xy-plot__axis__tick {
      .rv-xy-plot__axis__tick__line {
        stroke-width: 0.6;
        font-size: $text_size_6;
      }
    }
  }
}

.google-map {
  height: 100%;
}

.country {
  display: flex;
}

.ant-form-item-control-input-content {
  .ant-select {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        padding-top: 2px;
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.ant-btn {
  &.ant-btn-primary[disabled] {
    background: $primary_color;
    color: $white_color;
    opacity: 0.5;

    &:hover {
      background: $primary_color;
      color: $white_color;
      opacity: 0.5;
    }
  }
}

.ant-dropdown {
  &.overlay-user-details-card {
    left: 20% !important;
  }
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.ant-table-container::after {
  display: none;
}

.icon-color {
  color: $light_gray_color;
}

.ant-form-item-explain-error > * {
  font-size: $text_size_6;
}

.vehicles-content,
.issues-content {
  .ant-table-thead {
    th {
      &.ant-table-cell {
        line-height: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}
.users-content {
  .ant-table-thead {
    th {
      &.ant-table-cell {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
      }
    }
  }
}

.primary-color {
  color: $primary_color;
}

.secondary-color {
  color: $secondary_color;
}

.ant-upload-list-text-container {
  .ant-tooltip {
    display: none;
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 6px;
  }
}

.rv-crosshair {
  z-index: 10000;
}

.ant-picker {
  .ant-picker-input {
    input {
      color: $text_color_secondary;
    }
  }
}

.ant-radio,
.ant-radio-button {
  input[type="radio"]:focus {
    box-shadow: none;
    display: none;
  }

  .ant-radio:focus {
    box-shadow: none;
  }
}

p,
h1,
h2 {
  margin: 0 !important;
}

.value-with-color {
  position: relative;
  margin-left: 15px;

  &::before {
    content: "";
    top: 2px;
    right: calc(100% + 5px);
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 2px;
    background: var(--color-cell);
  }

  &.--circle {
    margin-left: 12px;

    &::before {
      background: $primary_color;
      width: 7px;
      height: 7px;
      top: 4px;
      border-radius: 50%;
    }
  }
}
