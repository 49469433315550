@import "styles/theme.scss";

.add-user {
  @include custom-modal-style(622px, 556px, 80px);

  .ant-modal-body {
    padding-right: 4px;

    .form-container {
      .description {
        font-size: $text_size_5;
        color: $text_color_secondary;

        .edit {
          margin-left: 5px;
        }
      }
      .ant-form {
        &.--add-users-form {
          @include form-modal(0, 225px, 146px);

          .ant-form-item-control {
            transition: all 0.3s;

            .ant-input {
              &.form-control {
                width: 225px;
              }
            }
          }

          .ant-upload-drag {
            height: 72px;

            .ant-upload {
              font-size: $text_size_5;
              padding: 0;

              &.ant-upload-btn {
                display: flex;
                align-items: center;
                margin-left: 18px;

                .ant-upload-drag-container {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 11px;
                }
              }
            }
          }

          .ant-select {
            .ant-select-selector {
              .ant-select-selection-item {
                color: $text_color_secondary;
                font-weight: 400;
                line-height: 35px;
              }
            }
          }

          .user-summary {
            .summary-label,
            .summary-value {
              padding-bottom: 10px;
              font-size: $text_size_5;;
            }

            .summary-label {
              color: $text_color_light;
              text-align: right;
            }

            .summary-values {
              color: $text_color_secondary;
              text-align: left;

              .summary-value {
                @include max-width-ellipsis(230px);

                &.--phone {
                  .react-tel-input {
                    height: 20px;

                    & > * {
                      font-size: $text_size_5;;
                    }

                    .login-phone-input {
                      padding-left: 20px;
                      border: none;
                      height: inherit;
                      cursor: text;
                    }

                    .user-flag {
                      .selected-flag {
                        padding-left: 0;

                        .flag {
                          border-radius: 2px;
                        }
                      }
                    }

                    .arrow {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .action-btn-container {
            padding-right: 26px;
          }
        }
      }
    }
  }
}

.uploadded-file {
  @include grid(18px 1fr 11px, auto, center, center, 14px);

  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 172px;
  height: 48px;
  padding: 5px 15px;

  .file-info {
    display: grid;

    .--name {
      @include max-width-ellipsis(10ch);

      color: $text_color_secondary;
      font-size: $text_size_5;
    }

    .--size {
      color: $text_color_light;
      font-size: $text_size_7;
    }
  }
}

.fixed-height-form {
  height: 455px;
  overflow-y: auto;
  overflow-x: hidden;

  & ~ .action-btn-container {
    padding-right: 22px;
    background-color: $white_color;
    height: 43px;
    align-items: flex-end;
  }
}
