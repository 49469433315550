@import "styles/mixins.scss";

.add-task {
  width: 607px !important;

  .--task-type {
    display: flex;
    align-items: center;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 4px;
    width: 375px;
    .ant-radio-button-wrapper {
      flex: 1;
      border: none;
      display: inline-flex;
      align-items: center;
      height: 36px;

      &::before {
        display: none;
      }

      .task-tab-title {
        display: inline-flex;
        align-items: center;
        gap: 7px;

        .tab-icon {
          color: $light_gray_color;
        }
      }

      &.ant-radio-button-wrapper-checked {
        .tab-icon {
          color: $white_color;
        }
      }
    }
  }

  .date-time {
    .ant-form-item-control-input-content {
      display: flex;
      gap: 10px;
    }
  }
  .ant-picker {
    width: 140px;
  }
  .filter-switch {
    &.--add_task {
      .ant-radio-button-wrapper {
        font-size: $text_size_5;
        padding: 0 8px;

        span {
          text-transform: lowercase;
        }
      }
    }
  }
  .ant-divider {
    padding-left: 16px;
    &::before {
      display: none;
    }
  }

  .task-list-section {
    min-height: 90px;
    max-height: 90px;
    overflow-y: auto;
    padding-bottom: 15px;

    .empty-list {
      @include grid((16px 1fr), auto, space-between, baseline, 11.4px);
      padding-left: 16px;
    }

    .task-list {
      @include grid((16px 1fr), auto, space-between, baseline, 11.4px);
      padding: 11px 18px;
      cursor: pointer;

      &:hover {
        background: $white_color;
        box-shadow: 0px 0px 5px rgba($black_color, 0.14);
      }
      .task {
        @include grid((1fr 16px), auto, space-between, center, 0);

        .task-title {
          color: $text_color_secondary;
          margin-bottom: 0;
        }
        .task-time {
          color: $text_color_light;
        }
      }
    }
  }

  .action-btn-container {
    margin-right: 70px;
    &.--footer {
      margin-right: 0;
      margin-top: 30px;
    }
  }
}
