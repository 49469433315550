.fixed-placement-bar-chart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .__title {
    color: $black_color;

    .custom-chart-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .left-side {
        .link {
          margin-left: 5px;
        }
      }

      .right-side {
        display: flex;
        align-items: flex-start;
        margin-top: 5px;

        .count {
          font-size: 36px;
          margin-right: 5px;
          line-height: normal;
        }
      }
    }
  }

  .__scale {
    margin-top: 40px;
    flex: 1;
    width: 100%;
    position: relative;

    .scale-structure {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      &.--disable {
        visibility: hidden;
      }

      .vertical-item {
        flex: 1;
        display: flex;
        height: 100%;
        flex-direction: column;

        .line {
          width: 1px;
          background: rgba($secondary_border_color, 0.3);
          flex: 1;
        }

        .label {
          padding-top: 5px;
        }

        &:last-of-type {
          position: absolute;
          right: 0;

          .line {
            align-self: flex-end;
          }
        }
      }
    }

    .bars {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .bar-item {
        flex: 1;
        position: relative;

        .bar-item-label {
          position: absolute;
          bottom: 100%;
          font-size: $text_size_5;;
          color: $text_color_secondary;
        }

        .outer-bar {
          position: relative;
          height: 16px;
          width: var(--outer_bar_width);
          background: var(--outer_bar_color);

          display: grid;
          place-items: center flex-start;

          .inner-bar {
            position: relative;
            // top: 20%;
            height: 6px;
            width: var(--inner_bar_width);
            background: var(--inner_bar_color);

            &.--same-height {
              top: 0;
              height: 100%;
            }
          }

          .render-content {
            position: absolute;
            left: calc(100% + 10px);
            top: 0;
          }
        }
      }
    }

    .scale-line {
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 30px;
      background: $secondary_border_color;

      &.--disable {
        visibility: hidden;
      }
    }
  }
}
