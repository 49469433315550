@import "styles/theme.scss";
@import "styles/mixins.scss";

.ant-table-wrapper {
  &.--dashboard-table {
    .ant-table-body {
      padding-right: 14px;
    }
  }
}

.common-table {
  :not(&.--common-list-table, &.--simple-table, &.--simple-two-columns, &.--without-height) {
    .ant-table-thead {
      .ant-table-cell {
        background: transparent;
        color: $black_color;
        line-height: 24px;
        font-weight: 600;
      }

      .ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }

    .ant-table-row {
      &:nth-child(odd):not(.white-background-row) {
        background: rgba($gray_color, 0.07);
      }

      .ant-table-cell {
        padding-top: 7px;
        padding-bottom: 7px;
        line-height: 16px;
      }

      &.white-background-row:hover {
        .ant-table-cell {
          background-color: #fff;
        }
      }
    }
  }

  &.--common-list-table {
    .ant-table-cell {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &.--dashboard-table {
    .ant-table-thead {
      .ant-table-cell {
        padding: 6px 16px;
        border-bottom: none;
      }
    }

    .ant-table-row {
      &:hover {
        box-shadow: none;
      }

      .ant-table-cell {
        border-bottom: 1px dashed $secondary_border_color;
        background-color: $white_color;

        &:not(:last-child) {
          border-right: 1px dashed $secondary_border_color;
        }
      }

      &.--vehicle {
        .ant-table-cell {
          &:nth-child(2n) {
            border-right: none;
          }
        }
      }
    }
  }

  &.--simple-two-columns {
    .ant-table {
      .ant-table-title {
        padding: 0;
        color: $black_color;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: $text_size_4;
      }

      height: unset;
      tbody {
        tr {
          &:hover {
            box-shadow: none;
          }
          td {
            border-bottom-color: transparent;
            background: $white_color;
            padding: 4px 0 !important;

            &:first-of-type {
              color: $text_color_light;
              font-size: $text_size_5;
            }
          }
        }
      }
    }
  }

  &.--custom-height {
    .ant-table {
      height: var(--table-height);
    }
  }

  .ant-table-row {
    .ant-table-cell {
      .text-light {
        color: rgba($text_color_secondary, 0.6);
      }
    }
  }
}

.pagination {
  @include flex-box(row, space-between, center);

  color: $text_color_light;
  font-size: $text_size_5;
  padding: 14px 10px;
  line-height: 24px;

  .paginationButtons-container {
    @include flex-box(row, center, center);

    @include gap(24px);

    .paginationButton {
      cursor: pointer;
      color: $light_gray_color;
    }
  }
}
