.next-step-form-container {
  margin-top: 68px;
  margin-left: 215px;
  max-width: 560px;

  .step {
    padding-left: 30px;
  }

  .next-step-title {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 22px;

    .title-icon {
      color: $error_color;
      margin-right: 18px;
    }
  }

  .next-step-form {
    padding-left: 30px;

    .message,
    .action-btn-container,
    .step-file-upload {
      max-width: 518px;
    }

    .ant-upload {
      &.step-file-upload {
        width: 518px;
        height: 72px;

        .ant-upload-btn {
          place-items: center flex-start;
          padding: 10px 18px;

          @include gap(11px);

          .file-info {
            place-items: center flex-start;
            text-align: left;
          }
        }
      }
    }

    .ant-upload-list {
      &.ant-upload-list-text {
        display: none;
      }
    }

    .message {
      font-size: 20px;

      &::placeholder {
        font-size: 20px;
      }
    }

    .action-btn-container {
      @include flex-box(row, flex-end, center);
      @include gap(8px);

      .ant-btn {
        width: 100px;
        height: 40px;
      }
    }
  }
}

.next-stepcomplete-state {
  @include flex-box(column, center, center);

  gap: 5px;
  height: 100%;

  > * {
    font-size: 16px;
    text-align: center;
  }

  .title {
    line-height: 24px;
  }

  .description {
    line-height: 22px;
    max-width: 415px;
  }
}
