@import "styles/theme.scss";
@import "styles/mixins.scss";

.import-csv {
  &.ant-upload {
    .ant-upload-btn {
      .ant-upload-drag-container {
        @include flex-box(row, flex-start, center);

        gap: 11px;
        margin-left: 18px;

        .ant-upload {
          font-size: $text_size_5;
          padding: 0;

          .ant-upload-btn {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
        }
      }
    }
  }
}

.ant-upload-list {
  .ant-upload-list-text-container {
    .ant-upload-text-icon {
      .anticon {
        color: $blue_color;
      }
    }
    .ant-upload-list-item-name {
      color: $blue_color;
    }
  }
}

.action-btn-container {
  &.--vehicle {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 17px;

    .ant-btn {
      height: 34px;
      width: 74px;
    }
  }
}
