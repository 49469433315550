.full-height {
  height: 100%;
}

  .container-element {
    height: calc(100vh-103px);
    height: 89.1vh;
  }

  .gm-style-iw-chr {
      height: 10px !important;
  }
