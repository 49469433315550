@import "styles/mixins.scss";

.add-payment {
  @include custom-modal-style(558px, 586px, 70px);

  .description,
  .ant-picker {
    font-size: $text_size_5;
  }

  .ant-select {
    &.custom-select {
      width: 262px;
    }
  }

  .ant-input {
    &.text-input {
      width: 262px;
    }
    &.number-input {
      width: 130px;
    }
  }

  .ant-picker {
    width: 225px;
  }

  .date-time-container {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .filter-switch {
    &.--payment {
      .ant-radio-button-wrapper {
        font-size: $text_size_5;
        padding: 0 8px;

        &.ant-radio-button-wrapper-checked {
          color: $white_color;
        }

        span {
          text-transform: lowercase;
        }
      }
    }
  }
}
