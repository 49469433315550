@import "styles/theme.scss";
@import "styles/mixins.scss";

.rv-xy-plot {
  &.--red-bars {
    @include tile-chart($error_color);
  }

  &.--orange-bars {
    @include tile-chart($secondary_color);
  }

  &.--green-bars {
    @include tile-chart($success_color);
  }

  &.--blue-bars {
    @include tile-chart($blue_color);
  }

  > svg {
    overflow: visible !important;
  }
}
