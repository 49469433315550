@import "styles/theme.scss";

.custom-form-item {
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 20px;

  .__label {
    width: 30%;
    text-align: right;
    padding-right: 10px;
    align-self: center;
    font-size: $text_size_5;
    // min-width: 160px;

    &.--required {
      &::after {
        content: "*";
        margin-left: 2px;
        color: $error_color;
      }
    }

    &.--vertical-top {
      align-self: flex-start;
    }

    &.--vertical-center {
      align-self: center;
    }
  }

  .__field-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .ant-input:not(.override-height) {
      height: 36px;
    }

    .error-text {
      @include max-width-ellipsis(100%);

      top: 100%;
      width: 100%;
      position: absolute;
      color: $error_color;
      font-size: $text_size_6;
    }

    .ant-upload-drag {
      background: $upload_bg_color;
      height: 72px;
      width: 346px;

      .ant-upload-btn {
        display: grid;
        // place-items: center start;
        place-items: center;
        padding: 10px 10px 6px;

        .ant-upload-drag-container {
          @include grid(auto auto, auto, flex-start, center, 11px);

          .ant-upload {
            &.text {
              padding: 0;
              font-size: $text_size_5;
              color: $text_color_secondary;
            }
          }
        }
      }
    }

    .start-shift,
    .end-shift {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .ant-picker {
      &.due-date {
        input {
          color: $text_color_secondary;
        }
      }
    }

    .custom-select {
      .ant-select-arrow {
        margin-bottom: 1px;
      }

      .ant-upload-drag {
        background: $upload_bg_color;

        .ant-upload-btn {
          display: grid;
          // place-items: center start;
          place-items: center;
          padding: 10px 10px 6px;
        }
      }

      .start-shift,
      .end-shift {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .custom-select {
    .ant-select-arrow {
      margin-bottom: 1px;
    }
  }

  .ant-select-selection-placeholder {
    margin-top: 2px;
  }
}

.--edit-shift-form,
.--add-payment-form {
  margin-top: 24px;

  .custom-form-item {
    .__label {
      min-width: 70;
    }
  }
}

.--add-payment-form {
  margin-bottom: 52px;
  max-height: 400px;
  overflow: auto;

  .__field-wrapper {
    .ant-select {
      .ant-select-selector {
        height: 36px;
      }
    }
  }
}

.--issues-modal-form {
  .custom-form-item {
    .__field-wrapper {
      .custom-select {
        .ant-select-selection-item {
          margin-top: 2px;
        }
      }
    }
  }
}

.--add-users-form,
.--edit-users-form,
.--add-vehicles-form {
  .custom-form-item {
    .__label {
      min-width: 156px;
    }

    .__field-wrapper {
      .custom-select {
        .ant-select-selector {
          .ant-select-selection-item {
            line-height: 36px;
          }
        }
      }
    }
  }
}

.--add-vehicles-form {
  .__field-wrapper {
    .custom-select {
      .ant-select-selector {
        .ant-select-selection-item {
          line-height: 36px;
        }
      }
    }
  }
}
