@import "styles/theme.scss";

.app-nav {
  &.ant-layout-sider-collapsed {
    padding: 0;
  }

  .nav-items {
    border: none;
    margin: 10px 0;
    width: 100%;
    color: $light_gray_color;

    .__item {
      display: flex;
      align-items: center;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-top: 0;
      padding-left: 24px;
      padding-right: 16px;

      .icon-span {
        display: inline-block;
        width: 26px;
        line-height: 0;
      }

      &::after {
        display: none;
      }
    }
  }
}
