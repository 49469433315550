@import "styles/theme.scss";
@import "styles/mixins.scss";


.custom-range-picker .ant-picker-range-separator {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  padding: 0 8px 8px ; /* Adjust padding if needed */
}

.label-container {
  height: 30px;

  .label {
    font-size: $text_size_6;
    font-weight: 400;

    > * {
      margin: 0;
    }

    .label-title {
      color: $text_color;
    }
    .label-value {
      color: $black_color;
    }
  }
}

.chart-card {
  .pie-chart-wrapper {
    width: 100%; // Make the container responsive
  max-width: 500px; // Set a maximum width
  height: auto; // Allow height to adjust
  aspect-ratio: 2.25; // Maintain aspect ratio (adjust as needed)
  @media (max-width: 768px) {
    max-width: 100%; // Full width on smaller screens
    aspect-ratio: 1; // Adjust aspect ratio for smaller screens
  }
    .pie-chart-container {
      height: 100%; // Make the container take full height of the wrapper
      .pie-chart-title {
        display: block;
        text-align: left;
        color: $black_color;
        margin: 0; // Remove default margin for better alignment
        padding: 16px; // Add padding to separate the title from the chart
        font-size: 18px; // Adjust font size as needed
        font-weight: bold; // Make the title bold
      }
  
      .empty-state-widget {
        display: grid;
        place-items: center;
        height: 100%; // Ensure the empty state takes full height
      }
    }
  }
}
