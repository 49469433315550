@import "styles/theme.scss";

.ant-layout-header {
  &.app-header {
    // position: fixed;
    width: 100%;
    height: 47px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white_color;
    padding: 0 20px;
    background:$black_color !important;

    .nav-icon {
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      color: white;
    }

    .--app-title-logo {
      display: flex;
      align-items: center;
      height: 47px;
      margin-right: auto;
    }

    .menu-btn {
      width: 27px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: $white_color;
      border: 0.4px solid $white_color;
      background: #D23427;
      box-sizing: border-box;
      cursor: pointer;
      text-transform: uppercase;

      &:hover,
      &.ant-dropdown-open {
        box-shadow: 0px 3px 4px 1px rgba($black_color, 0.25);
      }
    }
  }
}

.dropdowns-container {
  @include flex-box(row, center, center);

  height: 100%;

  @include gap(10px);

  .ant-select {
    width: 171px;

    .ant-select-arrow {
      color: $white_color;
    }

    &:nth-child(2) {
      width: 194px;
    }

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: #0f65a7;
        border: 1px solid #187ecd;

        &:hover {
          border: 1px solid #ffffff;
        }

        .ant-select-selection-item {
          color: white;
        }
      }
    }
  }

  .ant-select-arrow {
    color: $white_color;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid #ffffff;
  }

  .notification-icon {
    display: grid;
    place-items: center;
    position: relative;
  }
  .notification-badge {
    position: absolute;
    top: -32px;
    right: -10px;
    padding: 2px 5px;
    color: white;
    border-radius: 50%;
    font-size: 12px;
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    &.user-menu {
      width: 142px;

      padding: 8.8px 0 11px 0;

      .logout-menu {
        color: $error_color;
      }
    }
  }
}

.all-locations-label {
  margin-left: 12px;
  font-size: $text_size_5;;
}

.ant-select-item {
  &.ant-select-item-group {
    font-weight: 600;
  }
}

.app-access-notification {
  @include flex-box(row, center, center);
  @include gap(14px);

  height: 35px;
  font-size: $text_size_5;;
  background-color: $text_color_secondary;
  color: $white_color;
}
