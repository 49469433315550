@import "styles/mixins.scss";

.edit-shift {
  width: 422px !important;

  .description,
  .ant-picker {
    font-size: $text_size_5;
  }

  .ant-picker {
    width: 141px;
  }

  .filter-switch {
    &.--edit_shift {
      .ant-radio-button-wrapper {
        font-size: $text_size_5;
        padding: 0 8px;

        span {
          text-transform: lowercase;
        }
      }
    }
  }
}
