.delete-user,
.block-account {
  @include custom-modal-style(172px, 422px, 162px);
}

.delete-modal,
.block-user-modal,
.undo-delete-modal {
  .ant-typography,
  .ant-typography-secondary {
    margin-bottom: 8px;

    &.description {
      font-size: 13px;
    }
  }

  .action-btn-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    @include gap(8px);
  }
}
