@import "styles/theme.scss";

.dashboard-header {
  font-size: $text_size_5;

  > *:not(:last-child) {
    margin-right: 19px;
  }

  .ant-select {
    &.dashboard-dropdown {
      min-width: 159px;
      font-size: inherit;

      .ant-select-selector {
        border: 1px solid #e3e3e3;
        border-radius: 4px;
      }
      .ant-select-selection-item {
        color: $text_color_secondary;
        font-weight: 400;
      }
    }
  }

  .dashboard-header-label {
    font-weight: 400;
    margin-right: 6px;
  }

  .ant-typography {
    .filter-switch-label {
      @extend .dashboard-header-label;
    }

    &.ant-typography-secondary {
      color: $text_color_light;
    }
  }
}

.ant-select {
  &.selected-label {
    .ant-select-selector {
      .ant-select-selection-item {
        color: rgba($black_color, 0.6);
        font-size: 18px;
        font-weight: 500;
      }
    }

    .ant-select-arrow {
      font-size: 10px;
    }
  }
}
