@import "styles/theme.scss";

.custom-chart-label {
  @include flex-box(row, space-between, flex-start);

  min-width: 374px;

  .title {
    font-size: $text_size_4;;
  }

  .link {
    font-size: $text_size_5;;
    color: $link_color;
    margin-left: 5px;
  }

  .count {
    font-size: 36px;
    font-weight: 600;
    margin-right: 9px;
  }
}
