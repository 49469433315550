@import "styles/theme.scss";

.edit-user {
  @include custom-modal-style(638px, 556px, 70px);

  .ant-modal-body {
    padding-right: 4px;

    .form-container {
      .ant-form {
        &.--edit-users-form {
          @include form-modal(10px, 225px, 104px);

          // overflow-y: auto;

          .ant-form-item-control {
            transition: all 0.3s;

            .ant-input {
              &.form-control {
                width: 225px;
              }
            }
          }

          .ant-picker {
            width: 141px;
          }

          .start-shift,
          .end-shift {
            .ant-form-item-control-input-content {
              display: flex;
              gap: 11px;
            }

            .filter-switch {
              &.--edit_shift {
                .ant-radio-button-wrapper {
                  font-size: $text_size_5;
                  padding: 0 8px;

                  span {
                    text-transform: lowercase;
                  }
                }
              }
            }
          }
          .section-title {
            display: block;
            font-weight: 600;
            font-size: $text_size_4;
            line-height: 24px;
            color: $black_color;
            padding-left: 8px;
            margin-bottom: 13px;
          }
        }

        .step {
          font-size: $text_size_5;;
          color: $text_color_light;
          font-weight: 400;
        }
      }

      .action-btn-container {
        padding-top: 20px;
        padding-right: 18px;
      }
    }
  }
}
