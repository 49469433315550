@import "styles/theme.scss";

.alert-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  box-shadow: 0px 1px 8px rgba($black_color, 0.28);
  border-radius: 4px;

  &.success-border {
    border: 1px solid #80c58c;
  }

  &.error-border {
    border: 1px solid #f47979;
  }

  .message-wrapper {
    display: inline-flex;
    place-items: center;
    font-size: $text_size_6;
    line-height: 24px;
    gap: 3px;
    letter-spacing: 0.2px;

    .alert-type {
      text-transform: capitalize;

      &::after {
        content: "!";
      }
    }

    .alert-message {
      color: $text_color_secondary;
      font-size: $text_size_6;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
