@import "styles/theme.scss";

.loading-screen {
  position: absolute;
  inset: 0;
  background: rgba($white_color, 0.5);
  overflow: hidden;
  z-index: 100;

  &.--app-loading-screen {
    .progress-bar-wrapper {
      .progress-bar {
        margin-top: 1px;
      }
    }
  }

  .progress-bar-wrapper {
    position: relative;
    width: 100%;

    .progress-bar {
      background-color: $secondary_color;
      width: 10%;
      height: 5px;
      border-radius: 6px;
      animation: load 1.3s infinite ease-in-out;
      position: absolute;

      @keyframes load {
        0% {
          left: -100%;
          width: 20%;
        }
        50% {
          width: 80%;
        }
        100% {
          left: 100%;
          width: 20%;
        }
      }
    }
  }

  &.--app-loading-screen {
    margin-top: 47px;
  }
}
