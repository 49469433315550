.ant-checkbox-group {
  &.filter-checkbox-group {
    display: grid;
    grid-template-columns: 1fr;

    .ant-checkbox-wrapper {
      margin-left: 0;

      .ant-checkbox {
        .ant-checkbox-inner {
          border-radius: 2px;
        }
      }
    }
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 2px;

      &::after {
        border: 2px solid $text_color_secondary;
        border-top: 0;
        border-left: 0;
      }
    }
  }
}

.ant-collapse-item {
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding-top: 6px !important;
    }
  }
}
