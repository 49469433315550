.ant-tabs {
  &.tab-navs {
    margin-left: 2px;

    .ant-tabs-tab {
      padding: 5px 8px;
      text-transform: capitalize;
    }
  }
}
