@import "styles/theme.scss";
@import "styles/mixins.scss";

@include filters;

.common-filter {
  .__common-collapse {
    .ant-checkbox-group {
      &.filter-checkbox-group {
        max-height: 130px;
        overflow: auto;
      }
    }
    .issues-range-picker {
      margin-top: 10px;
    }

    .ant-collapse-content-box {
      .search-box {
        margin-bottom: 10px;
      }
    }

    .input-container {
      .ant-collapse-content-box {
        display: flex;

        .ant-input {
          width: 74px;
        }
      }
    }
  }
}

body {
  .ant-picker-range-separator {
    display: flex;
  }

  .issues-range-picker-popup {
    .ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border-color: $secondary_color;
        }
      }
    }

    .ant-picker-cell-range-end,
    .ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        background: $secondary_color;
        border-color: $secondary_color;

        &:hover,
        &:focus {
          background: $secondary_color;
          border-color: $secondary_color;
        }
      }
    }
  }
}
